import React from "react";
import NavBarComponent from "./components/NavBarComponent";
import HomePage from "./components/HomePage";
import Contact from "./components/Contact";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header>
        <NavBarComponent />
      </header>
        <div id="home">
          <HomePage />
        </div>
        <div id="contact">
          <Contact />
        </div>
      <footer id="footer">
        <p>&copy; 2024 RealtyOS Inc. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
