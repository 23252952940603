import React, { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import Select from "react-select"; // Import react-select
import Creatable from "react-select/creatable"; // Import Creatable from react-select
import { Form, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons"; // Example icons
import {
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { sendEmail } from "../services/emailService";
import { provinces, citiesByProvince } from "../data/canadianCities"; // Import data

const Conferences = () => {
  const [formError, setFormError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // State for success dialog

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    city: "",
    province: "",
    email: "",
    phoneNumber: "",
    nextConferenceDate: dayjs(), // Initialize with a Dayjs object
    expectedNumberOfAttendees: "",
    conferenceDetails: "",
    type: "Conference Demo request form",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    city: "",
    province: "",
    email: "",
    phoneNumber: "",
    nextConferenceDate: dayjs(),
    conferenceDetails: "",
  });

  const validateFirstName = (value) => {
    return value ? "" : "First name is required.";
  };

  const validateLastName = (value) => {
    return value ? "" : "Last name is required.";
  };

  const validateCompanyName = (value) => {
    return value ? "" : "Company name is required.";
  };

  const validateCity = (value) => {
    return value ? "" : "City is required.";
  };

  const validateProvince = (value) => {
    return value ? "" : "Province is required.";
  };

  const validateEmail = (value) => {
    if (!value) return "Email is required.";
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(value) ? "" : "Email is invalid.";
  };

  const validatePhoneNumber = (value) => {
    // Regex for North American phone numbers
    const phoneRegex =
      /^(?:\+1\s?)?\(?([2-9][0-9]{2})\)?[\s.-]?([2-9][0-9]{2})[\s.-]?([0-9]{4})$/;

    if (!value) {
      return "Phone number is required.";
    }

    if (!phoneRegex.test(value)) {
      return "Phone number is invalid. Please enter a valid North American phone number.";
    }

    return "";
  };
  const validateNextConferenceDate = (value) => {
    return value ? "" : "Next conference date is required.";
  };

  const validateExpectedNumberOfAttendees = (value) => {
    return value ? "" : "Expected number of attendees is required.";
  };

  const validateConferenceDetails = (value) => {
    return value ? "" : "Please tell us about your next conference.";
  };

  const [cityOptions, setCityOptions] = useState([]);
  const [provinceOptions] = useState(provinces);
  dayjs.extend(utc);

  const handleSelectChange = (selectedOption, actionMeta) => {
    if (actionMeta.name === "province") {
      setFormData({
        ...formData,
        [actionMeta.name]: selectedOption ? selectedOption.value : "",
        city: "", // Clear city when province is changed
      });
    } else {
      setFormData({
        ...formData,
        [actionMeta.name]: selectedOption ? selectedOption.value : "",
      });
    }
  };

  // Update city options based on selected province
  useEffect(() => {
    if (formData.province) {
      const cities = citiesByProvince[formData.province] || [];
      // Remove duplicates

      const uniqueCities = Array.from(
        new Map(cities.map((city) => [city.value, city])).values()
      );

      const sortedCities = uniqueCities.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setCityOptions(sortedCities);
    } else {
      setCityOptions([]);
    }
  }, [formData.province]);

  // Sort cityOptions alphabetically by the label
  const sortedCityOptions = [...cityOptions].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  // Handle city input changes
  const handleCityChange = (newValue) => {
    setFormData({
      ...formData,
      city: newValue ? newValue.value : "",
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // Validate all fields before submission
    const newErrors = {
      firstName: validateFirstName(formData.firstName),
      lastName: validateLastName(formData.lastName),
      companyName: validateCompanyName(formData.companyName),
      city: validateCity(formData.city),
      province: validateProvince(formData.province),
      email: validateEmail(formData.email),
      phoneNumber: validatePhoneNumber(formData.phoneNumber),
      nextConferenceDate: validateNextConferenceDate(
        formData.nextConferenceDate
      ),
      conferenceDetails: validateConferenceDetails(formData.conferenceDetails),
      expectedNumberOfAttendees: validateExpectedNumberOfAttendees(
        formData.expectedNumberOfAttendees
      ),
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return; // Prevent submission if validation fails
    }
    try {
      formData.emailFrom = "support@mg.mailgun.reacirc.com";
      formData.emailTo = "sales@realtyos.co";
      formData.emailName = `${formData.firstName} ${formData.lastName} - REACirc Brokage Demo Reques`;
      formData.emailSubject = `REACirc Conference Demo Request From ${formData.companyName} - REACirc Website`;
      formData.emailMessage = `<div style="text-align: left; border: 2px solid #000; padding: 16px; width: fit-content; margin: 0 auto; font-family: Arial, sans-serif;">
        <h1 style="text-align: center;">Demo Request</h1>
        <div style="margin-bottom: 8px;">
          <strong>Name:</strong> ${formData.firstName} ${formData.lastName}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>Company Name:</strong> ${formData.companyName}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>City:</strong> ${formData.city}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>Province:</strong> ${formData.province}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>Email:</strong> ${formData.email}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>Phone Number:</strong> ${formData.phoneNumber}
        </div>
        <div style="margin-bottom: 8px;">
          <strong>Next Conference Date:</strong> ${formData.nextConferenceDate.format(
            "YYYY-MM-DD"
          )}
        </div>
        <div>
          <strong>Expected # of Attendees:</strong> ${
            formData.expectedNumberOfAttendees
          }
        </div>
        <div>
          <strong>Conference Details:</strong> ${formData.conferenceDetails}
        </div>
      </div>`;
      const success = await sendEmail(formData);
      if (success) {
        setShowSuccessDialog(true); // Show success dialog
        setShowModal(false);
        setFormData({
          firstName: "",
          lastName: "",
          companyName: "",
          city: "",
          province: "",
          email: "",
          phoneNumber: "",
          nextConferenceDate: dayjs(),
          expectedNumberOfAttendees: "",
          conferenceDetails: "",
        });
        setFormError("");
      }
    } catch (error) {
      setErrors({ ...errors, form: error.message });
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });

      // Validate field on change
      let error = "";
      switch (name) {
        case "firstName":
          error = validateFirstName(value);
          break;
        case "lastName":
          error = validateLastName(value);
          break;
        case "brokerageName":
          error = validateCompanyName(value);
          break;
        case "city":
          error = validateCity(value);
          break;
        case "province":
          error = validateProvince(value);
          break;
        case "email":
          error = validateEmail(value);
          break;
        case "phoneNumber":
          error = validatePhoneNumber(value);
          break;
        case "nextConferenceDate":
          error = validateNextConferenceDate(value);
          break;
        case "expectedNumberOfAttendees":
          error = validateExpectedNumberOfAttendees(value);
          break;
        case "conferenceDetails":
          error = validateConferenceDetails(value);
          break;
        default:
          break;
      }

      setErrors({ ...errors, [name]: error });
    } else {
      console.error("Event target is undefined");
    }
  };
  return (
    <>
      <section id="hero" className="section conferences_bg__hero">
        <Container>
          <div className="row justify-content-center text-center">
            <div className="col-md-10">
              <div className="option-icon-brokerages">
                <FontAwesomeIcon icon={faCalendarDay} />
              </div>
              <h1>
                Unlock the Future of Conference Management with
                <br />
                <span className="highlight--yellow">REACirc!</span>
              </h1>
              <h2>
                <strong>REACirc for Conferences</strong> revolutionizes how you
                manage and deliver events, empowering organizers with
                cutting-edge tools to simplify logistics, foster seamless
                communication, and deliver unforgettable experiences for every
                attendee.
              </h2>
            </div>
          </div>
        </Container>
      </section>

      <section id="sectionOffset">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-10 bordered-list">
              <div className="block__list">
                <div className="text-center mb-4">
                  <h3 className="text-center mb-4">
                    Unlock the Potential of Your Conferences with REACirc
                  </h3>
                  <h5>
                    Transform Your Event Experience with a Unified Platform
                    Designed for Seamless Communication and Engagement
                  </h5>
                  <div style={{ margin: "1rem 0" }}>
                    Are you ready to elevate your conferences to new heights?
                    REACirc is your ultimate solution for managing and enhancing
                    attendee engagement. Discover how our specialized tools can
                    transform your conferences and provide an unparalleled
                    experience for both organizers and attendees.
                  </div>
                  <ul className="icon__check--blue mb-5">
                    <li>
                      <strong>Centralized Communication:</strong>
                      <br />
                      Effortlessly manage all conference communications from a
                      single, intuitive platform. Ensure no critical updates,
                      messages, or agenda adjustments are missed and keep
                      everyone on the same page, whether it’s staff, speakers,
                      or attendees.
                    </li>
                    <br />
                    <li>
                      <strong>Real-Time Updates:</strong>
                      <br />
                      Keep your event running smoothly with instant
                      notifications for schedule changes, speaker updates, and
                      crucial announcements. No more last-minute
                      confusion—everyone stays informed in real-time.
                    </li>
                    <br />
                    <li>
                      <strong>Event Scheduling:</strong>
                      <br />
                      Easily manage and share detailed event schedules.
                      Attendees and staff will always be up-to-date with
                      sessions, activities, and key timings, ensuring a
                      well-organized and engaging event.
                    </li>
                    <br />
                    <li>
                      <strong>Feedback Collection:</strong>
                      <br />
                      Harness the power of real-time feedback with integrated
                      surveys and forms. Collect valuable insights from
                      attendees to continuously improve and refine your future
                      events.
                    </li>
                    <br />
                    <li>
                      <strong>Interactive Features:</strong>
                      <br />
                      Engage your audience like never before with live polls,
                      interactive Q&A sessions, and dynamic schedules. Create a
                      vibrant and participatory atmosphere that keeps attendees
                      involved and excited.
                    </li>
                    <br />
                    <li>
                      <strong>Resource Management:</strong>
                      <br />
                      Organize and distribute essential resources, documents,
                      and presentations with ease. Ensure that all participants
                      have quick access to the materials they need for a
                      successful event.
                    </li>
                    <br />
                    <li>
                      <strong>Attendee Networking:</strong>
                      <br />
                      Foster meaningful connections by creating exclusive groups
                      for attendees, speakers, and organizers. Facilitate
                      networking and collaboration in a secure and organized
                      environment.
                    </li>
                    <br />
                    <li>
                      <strong>Secure Access:</strong>
                      <br />
                      Protect sensitive conference information with robust
                      security features. Only authorized participants can access
                      specific details, ensuring privacy and data protection.
                    </li>
                    <br />
                    <li>
                      <strong>And More...:</strong>
                      <br />
                      We are committed to continually enhancing REACirc with
                      innovative features to better support conference
                      organizers. Stay ahead of the curve with our evolving
                      platform designed to meet your unique needs.
                    </li>
                  </ul>
                  <div className="block__list--optin">
                    <h2>
                      Curious to see how REACirc can revolutionize your
                      conference management?
                    </h2>
                    <p>
                      Schedule a personalized demo today and experience
                      firsthand how our platform can elevate your next event.
                      <br />
                      <strong>
                        Contact us now to set up your demo and take the first
                        step towards a more efficient and engaging conference
                        experience!
                      </strong>
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowModal(true)}
                      style={{
                        backgroundColor: "#FFD700", // Yellow color
                        color: "#000", // Text color (black)
                        width: "100%", // Full width
                        maxWidth: "100%", // Ensures it does not exceed the viewport width
                        borderRadius: "8px", // Optional: rounded corners
                        margin: "20px 0", // Optional: adds space around the button
                      }}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1976d2", // Blue background
            color: "#FFFFFF", // White text
            fontWeight: "bold", // Bold text
            textAlign: "center", // Center text
            padding: "16px", // Adjust padding if needed
          }}
        >
          Schedule a Demo of REACirc for Conferences
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginTop: "15px" }} paragraph>
            We're excited to show you how REACirc can transform your brokerage.
            Please provide the following details to schedule your personalized
            demo.
          </Typography>
          <Form onSubmit={handleFormSubmit} className="form-box">
            <Form.Group>
              {formError && (
                <Typography color="error" variant="body2">
                  {formError}
                </Typography>
              )}
            </Form.Group>
            <Form.Group>
              <TextField
                label="First Name"
                name="firstName"
                fullWidth
                margin="dense"
                value={formData.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                required
              />
            </Form.Group>
            <Form.Group>
              <TextField
                label="Last Name"
                name="lastName"
                fullWidth
                margin="dense"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                required
              />
            </Form.Group>
            <Form.Group>
              <TextField
                label="Company Name"
                name="companyName"
                fullWidth
                margin="dense"
                value={formData.companyName}
                onChange={handleChange}
                error={!!errors.companyName}
                helperText={errors.companyName}
                required
                style={{ marginBottom: "12px" }} // Adjust the value as needed
              />
            </Form.Group>
            <Form.Group>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="nextConferenceDate"
                  label="Date of Next Conference"
                  value={formData.nextConferenceDate}
                  error={!!errors.nextConferenceDate}
                  helperText={errors.nextConferenceDate}
                  textField={(params) => (
                    <TextField {...params} fullWidth margin="dense" required />
                  )}
                />
              </LocalizationProvider>
            </Form.Group>
            <Form.Group>
              <TextField
                label="Tell Us about Your Conference"
                name="conferenceDetails"
                fullWidth
                margin="dense"
                value={formData.conferenceDetails}
                onChange={handleChange}
                error={!!errors.conferenceDetails}
                helperText={errors.conferenceDetails}
                required
                multiline
                rows={5} // Sets the number of visible text lines
              />
            </Form.Group>

            <Form.Group controlId="formProvince">
              <div
                style={{ margin: "10px 0", position: "relative", zIndex: 9999 }}
                className="form-select"
              >
                <InputLabel htmlFor="formCity">Province</InputLabel>
                <Select
                  name="province"
                  options={provinceOptions}
                  value={provinceOptions.find(
                    (option) => option.value === formData.province
                  )}
                  onChange={handleSelectChange}
                  placeholder="Select Province"
                  isClearable
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCity">
              <div
                style={{ margin: "10px 0", position: "relative", zIndex: 9998 }}
                className="form-select"
              >
                <InputLabel
                  style={{
                    margin: "10px 0",
                    position: "relative",
                    zIndex: 0,
                  }}
                  htmlFor="formCity"
                >
                  City
                </InputLabel>
                <Creatable
                  name="city"
                  options={cityOptions}
                  value={
                    sortedCityOptions.find(
                      (option) => option.value === formData.city
                    ) || { label: formData.city, value: formData.city }
                  }
                  onChange={handleCityChange}
                  placeholder="Select or enter city"
                  id="formCity"
                />
                <span style={{ marginLeft: "5px", fontSize: "10px" }}>
                  Type in your city if not found in the list
                </span>
              </div>
            </Form.Group>
            <Form.Group>
              <TextField
                label="Email"
                name="email"
                type="email"
                fullWidth
                margin="dense"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                required
              />
            </Form.Group>
            <Form.Group>
              <TextField
                label="Phone Number"
                name="phoneNumber"
                fullWidth
                margin="dense"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                required
              />
            </Form.Group>
            <Form.Group>
              <TextField
                label="Expected Number of Attendees"
                name="expectedNumberOfAttendees"
                fullWidth
                margin="dense"
                value={formData.expectedNumberOfAttendees}
                onChange={handleChange}
                error={!!errors.expectedNumberOfAttendees}
                helperText={errors.expectedNumberOfAttendees}
                required
              />
            </Form.Group>
            <Form.Group>
              {formError && (
                <Typography color="error" variant="body2">
                  {formError}
                </Typography>
              )}
            </Form.Group>
          </Form>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button
            type="submit"
            variant="success"
            color="primary"
            onClick={handleFormSubmit}
          >
            Submit Demo Request
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
      >
        <DialogTitle>Success!</DialogTitle>
        <DialogContent>
          <Typography>
            Your demo request has been successfully submitted!
            <br />
            We will be in contact soon...{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSuccessDialog(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Conferences;
