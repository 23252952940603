// src/components/Contact.js

import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { sendEmail } from "../services/emailService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./Contact.css"; // Optional: for custom styles

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
  const [formData, setFormData] = useState({
    emailName: "",
    emailFrom: "",
    emailMessage: "",
    emailTo: "sales@realtyos.co",
    emailSubject: "Contact Form Submission - Contact Form - REACirc Website",
  });

  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.emailName || !formData.emailFrom || !formData.emailMessage) {
      setFormError("Please fill in all fields.");
      return;
    }

    try {
      const emailData = {
        emailTo: "sales@realtyos.co",
        emailFrom: "support@mg.mailgun.reacirc.com",
        emailSubject: `Contact From ${formData.emailName} <${formData.emailFrom}> - Contact Form - REACirc Website`,
        emailName: formData.emailName,
        emailMessage: `Name: ${formData.emailName}<br/>Email: ${formData.emailFrom}<br/><br/><b>Message:</b><br/>${formData.emailMessage}`,
      };
      console.log(
        "emailTo",
        emailData.emailTo,
        "emailFrom",
        emailData.emailFrom,
        "emailSubject",
        emailData.emailSubject,
        "emailName",
        emailData.emailName,
        "emailMessage",
        emailData.emailMessage
      );
      const result = await sendEmail(emailData);
      setFormSuccess(result?.msg);
      // console.log("result", result?.msg);
      setFormData({ emailName: "", emailFrom: "", emailMessage: "" });
      setFormError("");
      setSnackbarOpen(true);
    } catch (error) {
      setFormError(error.message);
    }
  };

  return (
    <Container className="contact-container">
      <h2>Contact Us</h2>
      <p>Have questions or need more information? Reach out to us at:</p>
      <address>
        <strong>Email:</strong>{" "}
        <a href="mailto:support@reacirc.com">support@reacirc.com</a>
        <br />
        <a href="https://reacirc.com" target="_blank" rel="noopener noreferrer">
          www.reacirc.com
        </a>
      </address>

      <h3>Send Us a Message</h3>
      <Form onSubmit={handleSubmit} className="contact-form">
        <Form.Group controlId="formName">
          <Form.Control
            type="text"
            placeholder="Your Name"
            name="emailName"
            value={formData.emailName}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder="Your Email"
            name="emailFrom"
            value={formData.emailFrom}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Your Message"
            name="emailMessage"
            value={formData.emailMessage}
            onChange={handleChange}
            required
          />
        </Form.Group>
        {formError && <p className="text-danger">{formError}</p>}
        <Button type="submit" variant="primary">
          Send Message
        </Button>
      </Form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {formSuccess}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;
