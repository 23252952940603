import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import "./NavBarComponent.css"; // Ensure this matches the file name for CSS if applicable
import logo from "../assets/img/reacirc-logo.png"; // Adjust the path based on where you place your logo

const NavBarComponent = () => {
  return (
    <Navbar sx={{padding: "1rem"}} bg="primary" variant="dark" expand="sm">
      <Navbar.Brand href="#">
        <img
          src={logo}
          alt="REACirc Logo"
          className="navbar-logo"
          style={{ width: "40px", height: "40px", marginRight: "10px" }} // Adjust size and margin as needed
        />
        REACirc <span className="pronunciation">(Pronounced: Rea-Cirk)</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav className="mr-auto">
          {/* Uncomment and modify these as needed */}
          {/* <Nav.Link href="#sectionOffset">Features</Nav.Link> */}
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#footer">Contact</Nav.Link>
          {/* <Nav.Link href="#">About</Nav.Link> */}
        </Nav>
        <Nav className="ml-auto">
          <Button
            variant="outline-light"
            href="https://rlp.reacirc.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to REACirc
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarComponent;
