import React, { useEffect, useState } from "react";
import { Container, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react"; // Update this import
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "./HomePage.css"; // For HomePage specific styles
import "./Brokerages.css"; // For Brokerages specific styles
import "./Agents.css"; // For Agents specific styles
import "./Broker.css"; // For Agents specific styles
import "./NSC2024.css"; // For NSC2024 specific styles
import "./Conferences.css"; // For Conferences specific styles

import nsc2024Logo from "../assets/img/nsc2024-logo.png"; // Adjust the path according to your project structure

import Brokerages from "./Brokerages";
import Agents from "./Agents";
import Broker from "./Broker";
import Conferences from "./Conferences";
import NSC2024 from "./NSC2024";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons"; // Example icons
//test
const HomePage = () => {
  const [selectedSection, setSelectedSection] = useState(""); // Start with no selection
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState(null);
  const [params] = useState(new URLSearchParams(window.location.search));
  const [showQR, setShowQR] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    const section = params.get("section");
    if (section) {
      setSelectedSection(section);
    }
    // Add this check for QR parameter
    const qr = params.get("QR");
    setShowQR(qr === "true");
  }, [params]);
  const [QRCodeValue, setQRCodeValue] = useState("");

  useEffect(() => {
    // Generate the QR code value whenever the selected section changes
    const baseUrl = window.location.origin + window.location.pathname;
    const newParams = new URLSearchParams({
      section: selectedSection,
      QR: "true",
    });
    const fullUrl = `${baseUrl}?${newParams.toString()}`;
    setQRCodeValue(fullUrl);
  }, [selectedSection, QRCodeValue]);

  useEffect(() => {
    const newParams = new URLSearchParams(window.location.search);
    newParams.set("section", selectedSection);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${newParams}`
    );
  }, [selectedSection]);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/register", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ email }),
      });

      if (!response.ok) throw new Error("Failed to register");

      window.location.href = "/success.html";
    } catch (error) {
      setFormError("Registration failed, please try again.");
    }
  };

  const handleSelection = (section) => {
    setSelectedSection(section);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Pronounced "Rea-CirK"
    </Tooltip>
  );

  return (
    <>
      {showQR && (
        <section id="qr-toggle" className="section text-center">
          <Container>
            <Button
              variant="primary"
              onClick={() => setShowQRCode(!showQRCode)}
              className="qr-toggle-button"
            >
              <FontAwesomeIcon icon={faQrcode} className="me-2" />
              {showQRCode ? " Hide QR Code" : " Show QR Code"}
            </Button>
          </Container>
        </section>
      )}

      <section id="intro" className="section text-center">
        <Container>
          <OverlayTrigger placement="top" overlay={renderTooltip}>
            <h1>
              <img
                src="/img/reacirc-logo.png"
                alt="REACirc Logo"
                className="logo"
              />
              {"   "}
              Welcome to <span className="tooltip-text">REACirc</span>
              <img
                src="/img/reacirc-logo.png"
                alt="REACirc Logo"
                className="logo"
              />
            </h1>
          </OverlayTrigger>
          <p className="intro-description">
            At <strong>REACirc</strong>, we're all about changing how real
            estate professionals connect and work together.
          </p>
          <p>
            REACirc is custom-designed to meet the diverse needs of the real
            estate industry.
          </p>

          <p className="intro-highlight">
            <strong>
              Choose the option below that aligns best with your needs
            </strong>
            <br />
            and discover how REACirc can enhance your professional journey:
          </p>
        </Container>
      </section>

      <section id="toggle-menu" className="section text-center">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="btn-group">
                <Button
                  variant="primary"
                  className={`option-button nsc2024-button ${
                    selectedSection === "nsc2024" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("nsc2024")}
                >
                  <div className="nscLogo">
                    <img
                      src={nsc2024Logo}
                      style={{ width: "150px" }}
                      alt="NSC 2024 Logo"
                    />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for NSC 2024</h2>
                      <p>
                        FREE: Maximize your NSC 2024 Experience with REACirc.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button brokerages-button ${
                    selectedSection === "brokerages" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("brokerages")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faBuilding} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Brokerages</h2>
                      <p>
                        Empower your brokerage with integrated tools for
                        communication and collaboration.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button agents-button ${
                    selectedSection === "agents" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("agents")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Agents</h2>
                      <p>
                        Connect with agents across North America to share
                        valuable ideas and insights.
                      </p>
                    </div>
                  </div>
                </Button>

                <Button
                  variant="primary"
                  className={`option-button conferences-button ${
                    selectedSection === "broker" ? "active" : ""
                  }`}
                  onClick={() => handleSelection("broker")}
                >
                  <div className="option-icon">
                    <FontAwesomeIcon icon={faCalendarDay} />
                  </div>
                  <div className="option-text-box">
                    <div className="option-text">
                      <h2>REACirc for Broker Owners/Managers</h2>
                      <p>
                        Connect with fellow broker owners and managers to share
                        insights and strategies.
                      </p>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="content" className="section">
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-10">
              {selectedSection === "nsc2024" && (
                <NSC2024
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              )}
              {selectedSection === "brokerages" && (
                <Brokerages
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              )}
              {selectedSection === "agents" && (
                <Agents
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              )}
              {selectedSection === "broker" && (
                <Broker
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              )}
              {selectedSection === "conferences" && (
                <Conferences
                  email={email}
                  handleEmailChange={handleEmailChange}
                  handleFormSubmit={handleFormSubmit}
                  formError={formError}
                />
              )}
            </div>
          </div>
        </Container>
      </section>

      {showQRCode && (
        <div className="qr-code-container">
          <QRCodeSVG
            value={window.location.href}
            size={256}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="H"
            includeMargin={true}
            imageSettings={{
              src: "/img/reacirc-logo.png",
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              excavate: true,
            }}
          />
          <Button
            variant="primary"
            onClick={() => {
              const svg = document.querySelector(".qr-code-container svg");
              const svgData = new XMLSerializer().serializeToString(svg);
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              const img = new Image();
              img.onload = () => {
                // Set canvas size to 10 inches at 300 DPI
                const dpi = 300;
                canvas.width = 10 * dpi;
                canvas.height = 10 * dpi;
                
                // Scale and draw the QR code
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // Load and draw the logo
                const logo = new Image();
                logo.onload = () => {
                  // Calculate position to center the logo
                  const logoSize = Math.min(canvas.width, canvas.height) / 4; // Logo size is 1/4 of QR code
                  const logoX = (canvas.width - logoSize) / 2;
                  const logoY = (canvas.height - logoSize) / 2;

                  ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

                  const pngFile = canvas.toDataURL("image/png");
                  const downloadLink = document.createElement("a");
                  downloadLink.download = "qr-code-with-logo.png";
                  downloadLink.href = pngFile;
                  downloadLink.click();
                };
                logo.src = "/img/reacirc-logo.png"; // Make sure this path is correct
              };
              img.src = "data:image/svg+xml;base64," + btoa(svgData);
            }}
          >
            Download QR Code
          </Button>
        </div>
      )}
    </>
  );
};

export default HomePage;
