// src/services/emailService.js
const API_URL = process.env.REACT_APP_BACKEND_URL;

export const sendEmail = async (formData) => {
  //   required fields: emailTo, emailFrom, emailSubject, emailName, emailMessage
  try {
    const response = await fetch(`${API_URL}/externals/send-web-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(
        result.error || "An error occurred while sending the message."
      );
    }

    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error(
      error.message || "An error occurred while sending the message."
    );
  }
};
