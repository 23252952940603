// src/data/canadianCities.js
export const provinces = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "YT", label: "Yukon" },
];

export const citiesByProvince = {
  AB: [
    { value: "Calgary", label: "Calgary" },
    { value: "Edmonton", label: "Edmonton" },
    { value: "Red Deer", label: "Red Deer" },
    { value: "Lethbridge", label: "Lethbridge" },
    { value: "St. Albert", label: "St. Albert" },
    { value: "Medicine Hat", label: "Medicine Hat" },
    { value: "Grande Prairie", label: "Grande Prairie" },
    { value: "Airdrie", label: "Airdrie" },
    { value: "Cold Lake", label: "Cold Lake" },
    { value: "Spruce Grove", label: "Spruce Grove" },
    { value: "Leduc", label: "Leduc" },
    { value: "Fort Saskatchewan", label: "Fort Saskatchewan" },
    { value: "Hinton", label: "Hinton" },
    { value: "Jasper", label: "Jasper" },
    { value: "Chestermere", label: "Chestermere" },
    { value: "Sylvan Lake", label: "Sylvan Lake" },
    { value: "Beaumont", label: "Beaumont" },
    { value: "Stony Plain", label: "Stony Plain" },
    { value: "Wetaskiwin", label: "Wetaskiwin" },
    { value: "Camrose", label: "Camrose" },
    { value: "High River", label: "High River" },
    { value: "Strathcona County", label: "Strathcona County" },
    { value: "Drayton Valley", label: "Drayton Valley" },
    { value: "Barhead", label: "Barhead" },
    { value: "Bashaw", label: "Bashaw" },
    { value: "Brooks", label: "Brooks" },
    { value: "Calmar", label: "Calmar" },
    { value: "Didsbury", label: "Didsbury" },
    { value: "Fort Macleod", label: "Fort Macleod" },
    { value: "High Prairie", label: "High Prairie" },
    { value: "Innisfail", label: "Innisfail" },
    { value: "La Crete", label: "La Crete" },
    { value: "Lacombe", label: "Lacombe" },
    { value: "Mayerthorpe", label: "Mayerthorpe" },
    { value: "Nanton", label: "Nanton" },
    { value: "Oyen", label: "Oyen" },
    { value: "Pincher Creek", label: "Pincher Creek" },
    { value: "Provost", label: "Provost" },
    { value: "Rocky Mountain House", label: "Rocky Mountain House" },
    { value: "Slave Lake", label: "Slave Lake" },
    { value: "Stettler", label: "Stettler" },
    { value: "Viking", label: "Viking" },
    { value: "Wainwright", label: "Wainwright" },
    { value: "Acheson", label: "Acheson" },
    { value: "Athabasca", label: "Athabasca" },
    { value: "Beaverlodge", label: "Beaverlodge" },
    { value: "Bowden", label: "Bowden" },
    { value: "Breton", label: "Breton" },
    { value: "Calgary", label: "Calgary" },
    { value: "Camrose", label: "Camrose" },
    { value: "Clairmont", label: "Clairmont" },
    { value: "Cochrane", label: "Cochrane" },
    { value: "Conklin", label: "Conklin" },
    { value: "Crossfield", label: "Crossfield" },
    { value: "Eckville", label: "Eckville" },
    { value: "Edson", label: "Edson" },
    { value: "Elk Point", label: "Elk Point" },
    { value: "Fort Assiniboine", label: "Fort Assiniboine" },
    { value: "Fort MacKay", label: "Fort MacKay" },
    { value: "Gibbons", label: "Gibbons" },
    { value: "Glenwood", label: "Glenwood" },
    { value: "Hanna", label: "Hanna" },
    { value: "Joffre", label: "Joffre" },
    { value: "Lethbridge County", label: "Lethbridge County" },
    { value: "Macleod", label: "Macleod" },
    { value: "Manning", label: "Manning" },
    { value: "Morris", label: "Morris" },
    { value: "Oilers", label: "Oilers" },
    { value: "Peace River", label: "Peace River" },
    { value: "Sedgewick", label: "Sedgewick" },
    { value: "Seven Persons", label: "Seven Persons" },
    { value: "Sexsmith", label: "Sexsmith" },
    { value: "Stirling", label: "Stirling" },
    { value: "Thorsby", label: "Thorsby" },
    { value: "Torrington", label: "Torrington" },
    { value: "Warburg", label: "Warburg" },
    { value: "Water Valley", label: "Water Valley" },
    { value: "Wells", label: "Wells" },
    { value: "Westlock", label: "Westlock" },
    { value: "Willingdon", label: "Willingdon" },
    { value: "Winfield", label: "Winfield" },
    { value: "Wolverine", label: "Wolverine" },
  ],
  BC: [
    { value: "Abbotsford", label: "Abbotsford" },
    { value: "Burnaby", label: "Burnaby" },
    { value: "Chilliwack", label: "Chilliwack" },
    { value: "Downtown Vancouver", label: "Downtown Vancouver" },
    { value: "East Vancouver", label: "East Vancouver" },
    { value: "Fort St. John", label: "Fort St. John" },
    { value: "Fort St. James", label: "Fort St. James" },
    { value: "Kamloops", label: "Kamloops" },
    { value: "Kelowna", label: "Kelowna" },
    { value: "Kitsilano", label: "Kitsilano" },
    { value: "Langley", label: "Langley" },
    { value: "Mackenzie", label: "Mackenzie" },
    { value: "Masset", label: "Masset" },
    { value: "Nanaimo", label: "Nanaimo" },
    { value: "North Vancouver", label: "North Vancouver" },
    { value: "New Westminster", label: "New Westminster" },
    { value: "Prince George", label: "Prince George" },
    { value: "Penticton", label: "Penticton" },
    { value: "Port Alberni", label: "Port Alberni" },
    { value: "Port Hardy", label: "Port Hardy" },
    { value: "Port McNeill", label: "Port McNeill" },
    { value: "Quesnel", label: "Quesnel" },
    { value: "Richmond", label: "Richmond" },
    { value: "Salmon Arm", label: "Salmon Arm" },
    { value: "Sechelt", label: "Sechelt" },
    { value: "Smithers", label: "Smithers" },
    { value: "Squamish", label: "Squamish" },
    { value: "Surrey", label: "Surrey" },
    { value: "Tofino", label: "Tofino" },
    { value: "Trail", label: "Trail" },
    { value: "Ucluelet", label: "Ucluelet" },
    { value: "Vancouver", label: "Vancouver" },
    { value: "Vanderhoof", label: "Vanderhoof" },
    { value: "Victoria", label: "Victoria" },
    { value: "Whistler", label: "Whistler" },
    { value: "Williams Lake", label: "Williams Lake" },
    { value: "Campbell River", label: "Campbell River" },
    { value: "Comox", label: "Comox" },
    { value: "Courtenay", label: "Courtenay" },
    { value: "Parksville", label: "Parksville" },
    { value: "Qualicum Beach", label: "Qualicum Beach" },
    { value: "Sidney", label: "Sidney" },
    { value: "Salt Spring Island", label: "Salt Spring Island" },
    { value: "Armstrong", label: "Armstrong" },
    { value: "Bowen Island", label: "Bowen Island" },
    { value: "Chase", label: "Chase" },
    { value: "Clinton", label: "Clinton" },
    { value: "Creston", label: "Creston" },
    { value: "Dawson Creek", label: "Dawson Creek" },
    { value: "Enderby", label: "Enderby" },
    { value: "Gibsons", label: "Gibsons" },
    { value: "Golden", label: "Golden" },
    { value: "Harrison Hot Springs", label: "Harrison Hot Springs" },
    { value: "Hudson's Hope", label: "Hudson's Hope" },
    { value: "Kitsilano", label: "Kitsilano" },
    { value: "Lillooet", label: "Lillooet" },
    { value: "Lumby", label: "Lumby" },
    { value: "Merritt", label: "Merritt" },
    { value: "Nakusp", label: "Nakusp" },
    { value: "Nass Camp", label: "Nass Camp" },
    { value: "Osoyoos", label: "Osoyoos" },
    { value: "Pemberton", label: "Pemberton" },
    { value: "Princeton", label: "Princeton" },
    { value: "Radium Hot Springs", label: "Radium Hot Springs" },
    { value: "Revelstoke", label: "Revelstoke" },
    { value: "Rossland", label: "Rossland" },
    { value: "Salt Spring Island", label: "Salt Spring Island" },
    { value: "Stikine", label: "Stikine" },
    { value: "Stewart", label: "Stewart" },
    { value: "Tumbler Ridge", label: "Tumbler Ridge" },
    { value: "Valemount", label: "Valemount" },
  ],

  MB: [
    { value: "Winnipeg", label: "Winnipeg" },
    { value: "Brandon", label: "Brandon" },
    { value: "Steinbach", label: "Steinbach" },
    { value: "Portage la Prairie", label: "Portage la Prairie" },
    { value: "Thompson", label: "Thompson" },
    { value: "Winkler", label: "Winkler" },
    { value: "Morden", label: "Morden" },
    { value: "Selkirk", label: "Selkirk" },
    { value: "Flin Flon", label: "Flin Flon" },
    { value: "The Pas", label: "The Pas" },
    { value: "Dauphin", label: "Dauphin" },
    { value: "Churchill", label: "Churchill" },
    { value: "Niverville", label: "Niverville" },
    { value: "Gimli", label: "Gimli" },
    { value: "Carman", label: "Carman" },
    { value: "Grandview", label: "Grandview" },
    { value: "Oakbank", label: "Oakbank" },
    { value: "Pilot Mound", label: "Pilot Mound" },
    { value: "Teulon", label: "Teulon" },
    { value: "Altona", label: "Altona" },
    { value: "Arborg", label: "Arborg" },
    { value: "Baldur", label: "Baldur" },
    { value: "Birtle", label: "Birtle" },
    { value: "Carberry", label: "Carberry" },
    { value: "Elkhorn", label: "Elkhorn" },
    { value: "Ethelbert", label: "Ethelbert" },
    { value: "Hamiota", label: "Hamiota" },
    { value: "Holland", label: "Holland" },
    { value: "Killarney", label: "Killarney" },
    { value: "MacGregor", label: "MacGregor" },
    { value: "Manitou", label: "Manitou" },
    { value: "Moosehorn", label: "Moosehorn" },
    { value: "Morris", label: "Morris" },
    { value: "Narcisse", label: "Narcisse" },
    { value: "Plum Coulee", label: "Plum Coulee" },
    { value: "Russell", label: "Russell" },
    { value: "Swan River", label: "Swan River" },
    { value: "Wawanesa", label: "Wawanesa" },
    { value: "Waskada", label: "Waskada" },
    { value: "Whitemouth", label: "Whitemouth" },
    { value: "Woodlands", label: "Woodlands" },
    { value: "Shilo", label: "Shilo" },
    { value: "Richer", label: "Richer" },
    { value: "Carman", label: "Carman" },
    { value: "Baldur", label: "Baldur" },
    { value: "Oak Lake", label: "Oak Lake" },
    { value: "Souris", label: "Souris" },
    { value: "Minnedosa", label: "Minnedosa" },
    { value: "Neepawa", label: "Neepawa" },
    { value: "Shoal Lake", label: "Shoal Lake" },
    { value: "Virden", label: "Virden" },
    { value: "Glenboro", label: "Glenboro" },
    { value: "Lundar", label: "Lundar" },
    { value: "Erickson", label: "Erickson" },
    { value: "Teulon", label: "Teulon" },
    { value: "La Rivière", label: "La Rivière" },
    { value: "Long Plain First Nation", label: "Long Plain First Nation" },
    { value: "Sagkeeng First Nation", label: "Sagkeeng First Nation" },
    { value: "Shoal Lake", label: "Shoal Lake" },
    { value: "Valleyview", label: "Valleyview" },
    { value: "Manitoba Beach", label: "Manitoba Beach" },
    { value: "Rivers", label: "Rivers" },
    { value: "Cross Lake", label: "Cross Lake" },
    { value: "Gods Lake Narrows", label: "Gods Lake Narrows" },
    { value: "Gods River", label: "Gods River" },
    { value: "Ochiichagwe'babigo'ineeg", label: "Ochiichagwe'babigo'ineeg" },
  ],
  NB: [
    { value: "Fredericton", label: "Fredericton" },
    { value: "Saint John", label: "Saint John" },
    { value: "Moncton", label: "Moncton" },
    { value: "Dieppe", label: "Dieppe" },
    { value: "Bathurst", label: "Bathurst" },
    { value: "Miramichi", label: "Miramichi" },
    { value: "Edmundston", label: "Edmundston" },
    { value: "Campbellton", label: "Campbellton" },
    { value: "Rothesay", label: "Rothesay" },
    { value: "Quispamsis", label: "Quispamsis" },
    { value: "Grand Bay-Westfield", label: "Grand Bay-Westfield" },
    { value: "Woodstock", label: "Woodstock" },
    { value: "Oromocto", label: "Oromocto" },
    { value: "Caraquet", label: "Caraquet" },
    { value: "Shippagan", label: "Shippagan" },
    { value: "Tracadie", label: "Tracadie" },
    { value: "Saint-Louis-de-Kent", label: "Saint-Louis-de-Kent" },
    { value: "Saint-Quentin", label: "Saint-Quentin" },
    { value: "Tobique First Nation", label: "Tobique First Nation" },
    { value: "Minto", label: "Minto" },
    { value: "Nackawic", label: "Nackawic" },
    { value: "Hanwell", label: "Hanwell" },
    { value: "Hampton", label: "Hampton" },
    { value: "Riverside-Albert", label: "Riverside-Albert" },
    { value: "Sackville", label: "Sackville" },
    { value: "St. Andrews", label: "St. Andrews" },
    { value: "St. Stephen", label: "St. Stephen" },
    { value: "Lamèque", label: "Lamèque" },
    { value: "New Maryland", label: "New Maryland" },
    { value: "Richibucto", label: "Richibucto" },
    { value: "Black's Harbour", label: "Black's Harbour" },
    { value: "Boiestown", label: "Boiestown" },
    { value: "Frye", label: "Frye" },
    { value: "Murray Corner", label: "Murray Corner" },
    { value: "Shediac", label: "Shediac" },
    { value: "Berwick", label: "Berwick" },
    { value: "Grand Manan", label: "Grand Manan" },
    { value: "Cocagne", label: "Cocagne" },
    { value: "St. Martin", label: "St. Martin" },
    { value: "Wickham", label: "Wickham" },
    { value: "Sainte-Anne-de-Madawaska", label: "Sainte-Anne-de-Madawaska" },
    {
      value: "Sainte-Marie-Saint-Raphaël",
      label: "Sainte-Marie-Saint-Raphaël",
    },
    { value: "Balmoral", label: "Balmoral" },
    { value: "Bathurst", label: "Bathurst" },
    { value: "Béton", label: "Béton" },
    { value: "Big River", label: "Big River" },
    { value: "Canning", label: "Canning" },
    { value: "Dover", label: "Dover" },
    { value: "Maddox", label: "Maddox" },
    { value: "Gagetown", label: "Gagetown" },
    { value: "Maine", label: "Maine" },
    { value: "Clarks Corner", label: "Clarks Corner" },
    { value: "Houlton", label: "Houlton" },
    { value: "Stanley", label: "Stanley" },
    { value: "Gravel Hill", label: "Gravel Hill" },
    { value: "Saint-Marie", label: "Saint-Marie" },
    { value: "Grand Falls", label: "Grand Falls" },
    { value: "Glenwood", label: "Glenwood" },
    { value: "Gloucester", label: "Gloucester" },
    { value: "Hanover", label: "Hanover" },
    { value: "Havelock", label: "Havelock" },
    { value: "Kedgwick", label: "Kedgwick" },
    { value: "Kingston", label: "Kingston" },
    { value: "Kingsclear", label: "Kingsclear" },
    { value: "Saint John", label: "Saint John" },
  ],
  NL: [
    { value: "St. John's", label: "St. John's" },
    { value: "Mount Pearl", label: "Mount Pearl" },
    { value: "Conception Bay South", label: "Conception Bay South" },
    { value: "Paradise", label: "Paradise" },
    { value: "Gander", label: "Gander" },
    { value: "Grand Falls-Windsor", label: "Grand Falls-Windsor" },
    { value: "Corner Brook", label: "Corner Brook" },
    { value: "Labrador City", label: "Labrador City" },
    { value: "Happy Valley-Goose Bay", label: "Happy Valley-Goose Bay" },
    { value: "Stephenville", label: "Stephenville" },
    { value: "Placentia", label: "Placentia" },
    { value: "Wabush", label: "Wabush" },
    { value: "Port aux Basques", label: "Port aux Basques" },
    { value: "Fogo", label: "Fogo" },
    { value: "Bonavista", label: "Bonavista" },
    { value: "Tors Cove", label: "Tors Cove" },
    { value: "Springdale", label: "Springdale" },
    { value: "Bishop's Falls", label: "Bishop's Falls" },
    { value: "Baie Verte", label: "Baie Verte" },
    { value: "Humber Valley", label: "Humber Valley" },
    { value: "Bell Island", label: "Bell Island" },
    { value: "Lark Harbour", label: "Lark Harbour" },
    { value: "Marystown", label: "Marystown" },
    { value: "Triton", label: "Triton" },
    { value: "Glenwood", label: "Glenwood" },
    { value: "Port Saunders", label: "Port Saunders" },
    { value: "Carmanville", label: "Carmanville" },
    { value: "Little Harbour", label: "Little Harbour" },
    { value: "South Brook", label: "South Brook" },
    { value: "Trinity", label: "Trinity" },
    { value: "Twillingate", label: "Twillingate" },
    { value: "Change Islands", label: "Change Islands" },
    { value: "Roddickton", label: "Roddickton" },
    { value: "Gander Bay", label: "Gander Bay" },
    { value: "Buchans", label: "Buchans" },
    { value: "Pouch Cove", label: "Pouch Cove" },
    { value: "Cox's Cove", label: "Cox's Cove" },
    { value: "Northwest Brook", label: "Northwest Brook" },
    { value: "New World Island", label: "New World Island" },
    { value: "Trepassey", label: "Trepassey" },
    { value: "Ramea", label: "Ramea" },
    { value: "Ferryland", label: "Ferryland" },
    { value: "St. Anthony", label: "St. Anthony" },
    { value: "Sheshatshiu", label: "Sheshatshiu" },
    { value: "North West River", label: "North West River" },
    { value: "Natuashish", label: "Natuashish" },
    { value: "Makkovik", label: "Makkovik" },
    { value: "Rigolet", label: "Rigolet" },
    { value: "Kangirsuk", label: "Kangirsuk" },
    { value: "Puvirnituq", label: "Puvirnituq" },
    { value: "Umiujaq", label: "Umiujaq" },
    { value: "Ivujivik", label: "Ivujivik" },
    { value: "Hopedale", label: "Hopedale" },
    { value: "Nain", label: "Nain" },
    { value: "Makkovik", label: "Makkovik" },
    { value: "Black Tickle", label: "Black Tickle" },
  ],

  NS: [
    { value: "Halifax", label: "Halifax" },
    { value: "Dartmouth", label: "Dartmouth" },
    { value: "Sydney", label: "Sydney" },
    { value: "Truro", label: "Truro" },
    { value: "New Glasgow", label: "New Glasgow" },
    { value: "Bridgewater", label: "Bridgewater" },
    { value: "Amherst", label: "Amherst" },
    { value: "Yarmouth", label: "Yarmouth" },
    { value: "Kentville", label: "Kentville" },
    { value: "Antigonish", label: "Antigonish" },
    { value: "Lunenburg", label: "Lunenburg" },
    { value: "Mahone Bay", label: "Mahone Bay" },
    { value: "Wolfville", label: "Wolfville" },
    { value: "Liverpool", label: "Liverpool" },
    { value: "Pictou", label: "Pictou" },
    { value: "Port Hawkesbury", label: "Port Hawkesbury" },
    { value: "Shelburne", label: "Shelburne" },
    { value: "Hantsport", label: "Hantsport" },
    { value: "Canso", label: "Canso" },
    { value: "Guysborough", label: "Guysborough" },
    { value: "Joggins", label: "Joggins" },
    { value: "Pugwash", label: "Pugwash" },
    { value: "Westville", label: "Westville" },
    { value: "Springhill", label: "Springhill" },
    { value: "Stellarton", label: "Stellarton" },
    { value: "Shubenacadie", label: "Shubenacadie" },
    { value: "Elmsdale", label: "Elmsdale" },
    { value: "Lantz", label: "Lantz" },
    { value: "Mount Uniacke", label: "Mount Uniacke" },
    { value: "Windsor", label: "Windsor" },
    { value: "Enfield", label: "Enfield" },
    { value: "Tatamagouche", label: "Tatamagouche" },
    { value: "Mahone Bay", label: "Mahone Bay" },
    { value: "Porters Lake", label: "Porters Lake" },
    { value: "Elmsdale", label: "Elmsdale" },
    { value: "Blandford", label: "Blandford" },
    { value: "Chester", label: "Chester" },
    { value: "Dartmouth", label: "Dartmouth" },
    { value: "Moser River", label: "Moser River" },
    { value: "Maddox Cove", label: "Maddox Cove" },
    { value: "Dufferin", label: "Dufferin" },
    { value: "Lawrencetown", label: "Lawrencetown" },
    { value: "Briar Island", label: "Briar Island" },
    { value: "Pictou Landing", label: "Pictou Landing" },
    { value: "Glenwood", label: "Glenwood" },
    { value: "Greenfield", label: "Greenfield" },
    { value: "Bear River", label: "Bear River" },
    { value: "Havre Boucher", label: "Havre Boucher" },
    { value: "Sambro", label: "Sambro" },
    { value: "Gaspereau", label: "Gaspereau" },
    { value: "Canning", label: "Canning" },
    { value: "Port Dufferin", label: "Port Dufferin" },
    { value: "Tantallon", label: "Tantallon" },
    { value: "Falmouth", label: "Falmouth" },
    { value: "Gold River", label: "Gold River" },
    { value: "St. Margaret's Bay", label: "St. Margaret's Bay" },
    { value: "Cheticamp", label: "Cheticamp" },
    { value: "Bayfield", label: "Bayfield" },
    { value: "River Hebert", label: "River Hebert" },
    { value: "Sherbrooke", label: "Sherbrooke" },
    { value: "Port Mouton", label: "Port Mouton" },
    { value: "East River", label: "East River" },
    { value: "Digby", label: "Digby" },
    { value: "Hubbards", label: "Hubbards" },
    { value: "Centreville", label: "Centreville" },
  ],

  NT: [
    { value: "Yellowknife", label: "Yellowknife" },
    { value: "Inuvik", label: "Inuvik" },
    { value: "Hay River", label: "Hay River" },
    { value: "Fort Smith", label: "Fort Smith" },
    { value: "Behchokǫ̀", label: "Behchokǫ̀" },
    { value: "Fort Simpson", label: "Fort Simpson" },
    { value: "Tuktoyaktuk", label: "Tuktoyaktuk" },
    { value: "Aklavik", label: "Aklavik" },
    { value: "Gjoa Haven", label: "Gjoa Haven" },
    { value: "Paulatuk", label: "Paulatuk" },
    { value: "Sachs Harbour", label: "Sachs Harbour" },
    { value: "Fort Resolution", label: "Fort Resolution" },
    { value: "Fort Liard", label: "Fort Liard" },
    { value: "Wrigley", label: "Wrigley" },
    { value: "Norman Wells", label: "Norman Wells" },
    { value: "Lutsel K'e", label: "Lutsel K'e" },
    { value: "Deline", label: "Deline" },
    { value: "Colville Lake", label: "Colville Lake" },
    { value: "Wekweeti", label: "Wekweeti" },
    { value: "Gamètì", label: "Gamètì" },
    { value: "Tsiigehtchic", label: "Tsiigehtchic" },
    { value: "Whati", label: "Whati" },
    { value: "Fort Good Hope", label: "Fort Good Hope" },
    { value: "Enterprise", label: "Enterprise" },
    { value: "Yellowknife", label: "Yellowknife" },
    { value: "Great Bear Lake", label: "Great Bear Lake" },
    { value: "Great Slave Lake", label: "Great Slave Lake" },
    { value: "Sachs Harbour", label: "Sachs Harbour" },
    { value: "Aklavik", label: "Aklavik" },
  ],

  NU: [
    { value: "Iqaluit", label: "Iqaluit" },
    { value: "Arviat", label: "Arviat" },
    { value: "Baker Lake", label: "Baker Lake" },
    { value: "Cambridge Bay", label: "Cambridge Bay" },
    { value: "Chesterfield Inlet", label: "Chesterfield Inlet" },
    { value: "Clyde River", label: "Clyde River" },
    { value: "Gjoa Haven", label: "Gjoa Haven" },
    { value: "Grise Fiord", label: "Grise Fiord" },
    { value: "Hall Beach", label: "Hall Beach" },
    { value: "Igloolik", label: "Igloolik" },
    { value: "Kimmirut", label: "Kimmirut" },
    { value: "Kugaaruk", label: "Kugaaruk" },
    { value: "Kugluktuk", label: "Kugluktuk" },
    { value: "Pangnirtung", label: "Pangnirtung" },
    { value: "Pond Inlet", label: "Pond Inlet" },
    { value: "Qikiqtarjuaq", label: "Qikiqtarjuaq" },
    { value: "Sanikiluaq", label: "Sanikiluaq" },
    { value: "Resolute", label: "Resolute" },
    { value: "Taloyoak", label: "Taloyoak" },
    { value: "Iqaluit", label: "Iqaluit" },
    { value: "Arctic Bay", label: "Arctic Bay" },
    { value: "Naujaat", label: "Naujaat" },
    { value: "Repulse Bay", label: "Repulse Bay" },
    { value: "Sachs Harbour", label: "Sachs Harbour" },
    { value: "Aupaluk", label: "Aupaluk" },
  ],
  ON: [
    { value: "Toronto", label: "Toronto" },
    { value: "Ottawa", label: "Ottawa" },
    { value: "Mississauga", label: "Mississauga" },
    { value: "Brampton", label: "Brampton" },
    { value: "Hamilton", label: "Hamilton" },
    { value: "London", label: "London" },
    { value: "Markham", label: "Markham" },
    { value: "Vaughan", label: "Vaughan" },
    { value: "Kitchener", label: "Kitchener" },
    { value: "Windsor", label: "Windsor" },
    { value: "Richmond Hill", label: "Richmond Hill" },
    { value: "Oakville", label: "Oakville" },
    { value: "Burlington", label: "Burlington" },
    { value: "Greater Sudbury", label: "Greater Sudbury" },
    { value: "Thunder Bay", label: "Thunder Bay" },
    { value: "Guelph", label: "Guelph" },
    { value: "Waterloo", label: "Waterloo" },
    { value: "Sarnia", label: "Sarnia" },
    { value: "St. Catharines", label: "St. Catharines" },
    { value: "Niagara Falls", label: "Niagara Falls" },
    { value: "North Bay", label: "North Bay" },
    { value: "Peterborough", label: "Peterborough" },
    { value: "Sudbury", label: "Sudbury" },
    { value: "Newmarket", label: "Newmarket" },
    { value: "Aurora", label: "Aurora" },
    { value: "Pickering", label: "Pickering" },
    { value: "Whitby", label: "Whitby" },
    { value: "Ajax", label: "Ajax" },
    { value: "Oshawa", label: "Oshawa" },
    { value: "Scarborough", label: "Scarborough" },
    { value: "Etobicoke", label: "Etobicoke" },
    { value: "York", label: "York" },
    { value: "East Gwillimbury", label: "East Gwillimbury" },
    { value: "Collingwood", label: "Collingwood" },
    { value: "Orillia", label: "Orillia" },
    { value: "Bracebridge", label: "Bracebridge" },
    { value: "Huntsville", label: "Huntsville" },
    { value: "Midland", label: "Midland" },
    { value: "Georgina", label: "Georgina" },
    { value: "Wasaga Beach", label: "Wasaga Beach" },
    { value: "Innisfil", label: "Innisfil" },
    { value: "Thorold", label: "Thorold" },
    { value: "Welland", label: "Welland" },
    { value: "Grimsby", label: "Grimsby" },
    { value: "Fort Erie", label: "Fort Erie" },
    { value: "Port Colborne", label: "Port Colborne" },
    { value: "Pelham", label: "Pelham" },
    { value: "Niagara-on-the-Lake", label: "Niagara-on-the-Lake" },
    { value: "Milton", label: "Milton" },
    { value: "Caledon", label: "Caledon" },
    { value: "Orangeville", label: "Orangeville" },
    { value: "Brockville", label: "Brockville" },
    { value: "Smiths Falls", label: "Smiths Falls" },
    { value: "Perth", label: "Perth" },
    { value: "Carleton Place", label: "Carleton Place" },
    { value: "Arnprior", label: "Arnprior" },
    { value: "Kawartha Lakes", label: "Kawartha Lakes" },
    { value: "Peterborough", label: "Peterborough" },
    { value: "St. Thomas", label: "St. Thomas" },
    { value: "Woodstock", label: "Woodstock" },
    { value: "Sault Ste. Marie", label: "Sault Ste. Marie" },
    { value: "Elliot Lake", label: "Elliot Lake" },
    { value: "Chapleau", label: "Chapleau" },
    { value: "Hornepayne", label: "Hornepayne" },
  ],

  PE: [
    { value: "Charlottetown", label: "Charlottetown" },
    { value: "Summerside", label: "Summerside" },
    { value: "Cornwall", label: "Cornwall" },
    { value: "Stratford", label: "Stratford" },
    { value: "Montague", label: "Montague" },
    { value: "Souris", label: "Souris" },
    { value: "Alberton", label: "Alberton" },
    { value: "Tignish", label: "Tignish" },
    { value: "Belfast", label: "Belfast" },
    { value: "O'Leary", label: "O'Leary" },
    { value: "Tyne Valley", label: "Tyne Valley" },
    { value: "Morell", label: "Morell" },
    { value: "Georgetown", label: "Georgetown" },
    { value: "Kinkora", label: "Kinkora" },
    { value: "South Rustico", label: "South Rustico" },
    { value: "Central Bedeque", label: "Central Bedeque" },
    { value: "Hampton", label: "Hampton" },
    { value: "North Rustico", label: "North Rustico" },
    { value: "Brackley Beach", label: "Brackley Beach" },
  ],
  QC: [
    { value: "Montreal", label: "Montreal" },
    { value: "Quebec City", label: "Quebec City" },
    { value: "Gatineau", label: "Gatineau" },
    { value: "Longueuil", label: "Longueuil" },
    { value: "Laval", label: "Laval" },
    { value: "Trois-Rivières", label: "Trois-Rivières" },
    { value: "Sherbrooke", label: "Sherbrooke" },
    { value: "Saint-Jean-sur-Richelieu", label: "Saint-Jean-sur-Richelieu" },
    { value: "Châteauguay", label: "Châteauguay" },
    { value: "Granby", label: "Granby" },
    { value: "Saint-Jérôme", label: "Saint-Jérôme" },
    { value: "Drummondville", label: "Drummondville" },
    { value: "Saint-Hyacinthe", label: "Saint-Hyacinthe" },
    { value: "Boucherville", label: "Boucherville" },
    { value: "Saint-Sauveur", label: "Saint-Sauveur" },
    { value: "Bromont", label: "Bromont" },
    { value: "Rimouski", label: "Rimouski" },
    { value: "Victoriaville", label: "Victoriaville" },
    { value: "Sorel-Tracy", label: "Sorel-Tracy" },
    { value: "Rouyn-Noranda", label: "Rouyn-Noranda" },
    { value: "Val-d'Or", label: "Val-d'Or" },
    { value: "La Tuque", label: "La Tuque" },
    { value: "Mont-Laurier", label: "Mont-Laurier" },
    { value: "Shawinigan", label: "Shawinigan" },
    { value: "Amos", label: "Amos" },
    { value: "Salaberry-de-Valleyfield", label: "Salaberry-de-Valleyfield" },
    { value: "Saint-Georges", label: "Saint-Georges" },
    { value: "Alma", label: "Alma" },
    { value: "Limoilou", label: "Limoilou" },
    { value: "Levis", label: "Levis" },
    { value: "Sept-Îles", label: "Sept-Îles" },
    { value: "Longueuil", label: "Longueuil" },
    { value: "Sainte-Julie", label: "Sainte-Julie" },
    { value: "Saint-Élie-de-Caxton", label: "Saint-Élie-de-Caxton" },
    { value: "Sainte-Adèle", label: "Sainte-Adèle" },
    { value: "Sainte-Catherine", label: "Sainte-Catherine" },
    { value: "Lachute", label: "Lachute" },
    { value: "Chibougamau", label: "Chibougamau" },
    { value: "L'Assomption", label: "L'Assomption" },
    { value: "Saint-Apollonia", label: "Saint-Apollonia" },
    { value: "Saint-Félix-de-Valois", label: "Saint-Félix-de-Valois" },
    { value: "Saint-Nicolas", label: "Saint-Nicolas" },
    {
      value: "Saint-Augustin-de-Desmaures",
      label: "Saint-Augustin-de-Desmaures",
    },
    { value: "Notre-Dame-des-Pins", label: "Notre-Dame-des-Pins" },
    { value: "Pincourt", label: "Pincourt" },
    { value: "Lavaltrie", label: "Lavaltrie" },
    { value: "Saint-Lambert", label: "Saint-Lambert" },
    { value: "Saint-Pierre-les-Becquets", label: "Saint-Pierre-les-Becquets" },
    { value: "Carleton-sur-Mer", label: "Carleton-sur-Mer" },
    { value: "New Carlisle", label: "New Carlisle" },
    { value: "Gaspé", label: "Gaspé" },
    { value: "Matane", label: "Matane" },
    { value: "Montmagny", label: "Montmagny" },
    { value: "Mont-Saint-Hilaire", label: "Mont-Saint-Hilaire" },
    {
      value: "Saint-Bruno-de-Montarville",
      label: "Saint-Bruno-de-Montarville",
    },
    { value: "Saint-Joseph-de-Beauce", label: "Saint-Joseph-de-Beauce" },
    { value: "Saint-Rémi", label: "Saint-Rémi" },
  ],

  YT: [
    { value: "Whitehorse", label: "Whitehorse" },
    { value: "Dawson City", label: "Dawson City" },
    { value: "Haines Junction", label: "Haines Junction" },
    { value: "Watson Lake", label: "Watson Lake" },
    { value: "Carmacks", label: "Carmacks" },
    { value: "Faro", label: "Faro" },
    { value: "Ross River", label: "Ross River" },
    { value: "Pelly Crossing", label: "Pelly Crossing" },
    { value: "Teslin", label: "Teslin" },
    { value: "Mayo", label: "Mayo" },
    { value: "Beaver Creek", label: "Beaver Creek" },
    { value: "Burwash Landing", label: "Burwash Landing" },
    { value: "Destruction Bay", label: "Destruction Bay" },
    { value: "Kluane Lake", label: "Kluane Lake" },
    { value: "Old Crow", label: "Old Crow" },
  ],
};
