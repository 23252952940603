import React, { useState } from "react";
import {
  Container,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { sendEmail } from "../services/emailService"; // Adjust path as needed

const Brokerages = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false); // State for success dialog
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    brokerageName: "",
    city: "",
    province: "",
    email: "",
    phoneNumber: "",
    numberOfOffices: "",
    totalNumberOfAgents: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    brokerageName: "",
    city: "",
    province: "",
    email: "",
    phoneNumber: "",
    numberOfOffices: "",
    totalNumberOfAgents: "",
    type: "Brokerage Demo request form",
  });


  const validateFirstName = (value) => {
    return value ? "" : "First name is required.";
  };

  const validateLastName = (value) => {
    return value ? "" : "Last name is required.";
  };

  const validateBrokerageName = (value) => {
    return value ? "" : "Brokerage name is required.";
  };

  const validateCity = (value) => {
    return value ? "" : "City is required.";
  };

  const validateProvince = (value) => {
    return value ? "" : "Province is required.";
  };

  const validateEmail = (value) => {
    if (!value) return "Email is required.";
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(value) ? "" : "Email is invalid.";
  };

 const validatePhoneNumber = (value) => {
   // Regex for North American phone numbers
   const phoneRegex =
     /^(?:\+1\s?)?\(?([2-9][0-9]{2})\)?[\s.-]?([2-9][0-9]{2})[\s.-]?([0-9]{4})$/;

   if (!value) {
     return "Phone number is required.";
   }

   if (!phoneRegex.test(value)) {
     return "Phone number is invalid. Please enter a valid North American phone number.";
   }

   return "";
 };

  const validateNumberOfOffices = (value) => {
    return value && !isNaN(value)
      ? ""
      : "Number of offices must be a valid number.";
  };

  const validateTotalNumberOfAgents = (value) => {
    return value && !isNaN(value)
      ? ""
      : "Total number of agents must be a valid number.";
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate all fields before submission
    const newErrors = {
      firstName: validateFirstName(formData.firstName),
      lastName: validateLastName(formData.lastName),
      brokerageName: validateBrokerageName(formData.brokerageName),
      city: validateCity(formData.city),
      province: validateProvince(formData.province),
      email: validateEmail(formData.email),
      phoneNumber: validatePhoneNumber(formData.phoneNumber),
      numberOfOffices: validateNumberOfOffices(formData.numberOfOffices),
      totalNumberOfAgents: validateTotalNumberOfAgents(
        formData.totalNumberOfAgents
      ),
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return; // Prevent submission if validation fails
    }
    try {
      //   required fields: emailTo, emailFrom, emailSubject, emailName, emailMessage
      formData.emailFrom = "support@mg.mailgun.reacirc.com";
      formData.emailTo = "sales@realtyos.co";
      formData.emailName = `${formData.firstName} ${formData.lastName} - REACirc Brokage Demo Reques`;
      formData.emailSubject = `Demo Request From ${formData.brokerageName} - REACirc Website`;
      formData.emailMessage = `<div style="text-align: left; border: 2px solid #000; padding: 16px; width: fit-content; margin: 0 auto; font-family: Arial, sans-serif;">
  <h1 style="text-align: center;">Demo Request</h1>
  <div style="margin-bottom: 8px;">
    <strong>Name:</strong> ${formData.firstName} ${formData.lastName}
  </div>
  <div style="margin-bottom: 8px;">
    <strong>Brokerage Name:</strong> ${formData.brokerageName}
  </div>
  <div style="margin-bottom: 8px;">
    <strong>City:</strong> ${formData.city}
  </div>
    </div>
    <div style="margin-bottom: 8px;">
    <strong>Province:</strong> ${formData.province}
  </div>
  <div style="margin-bottom: 8px;">
    <strong>Email:</strong> ${formData.email}
  </div>
  <div style="margin-bottom: 8px;">
    <strong>Phone Number:</strong> ${formData.phoneNumber}
  </div>
  <div style="margin-bottom: 8px;">
    <strong>Number of Offices:</strong> ${formData.numberOfOffices}
  </div>
  <div>
    <strong>Total Number of Agents:</strong> ${formData.totalNumberOfAgents}
  </div>
</div>`;

      const success = await sendEmail(formData);
      if (success) {
        setShowSuccessDialog(true); // Show success dialog
        setShowModal(false);
        setFormData({
          firstName: "",
          lastName: "",
          brokerageName: "",
          city: "",
          email: "",
          phoneNumber: "",
          numberOfOffices: "",
          totalNumberOfAgents: "",
        });
      }
    } catch (error) {
      setErrors({ ...errors, form: error.message });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate field on change
    let error = "";
    switch (name) {
      case "firstName":
        error = validateFirstName(value);
        break;
      case "lastName":
        error = validateLastName(value);
        break;
      case "brokerageName":
        error = validateBrokerageName(value);
        break;
      case "city":
        error = validateCity(value);
        break;
      case "province":
        error = validateProvince(value);
        break;
      case "email":
        error = validateEmail(value);
        break;
      case "phoneNumber":
        error = validatePhoneNumber(value);
        break;
      case "numberOfOffices":
        error = validateNumberOfOffices(value);
        break;
      case "totalNumberOfAgents":
        error = validateTotalNumberOfAgents(value);
        break;
      default:
        break;
    }

    setErrors({ ...errors, [name]: error });
  };
  return (
    <>
      <section id="hero" className="section brokerages_bg__hero">
        <Container>
          <div className="row justify-content-center text-center">
            <div className="col-md-10">
              <div className="option-icon-brokerages">
                <FontAwesomeIcon icon={faBuilding} />
              </div>
              <Typography variant="h1" align="center">
                Empower Your Real Estate Brokerage with <br />
                <span className="highlight--yellow">REACirc!</span>
                <Tooltip title="Pronounced 'Rea-Cirk'" arrow>
                  <IconButton>
                    <InfoIcon
                      fontSize="small"
                      style={{ position: "relative", top: "-10px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Typography
                variant="h2"
                align="center"
                sx={{ marginBottom: "20px" }}
              >
                <strong>REACirc for Brokerages</strong> <br />
                is your key to unlocking a new level of productivity and
                cohesion within your team. Imagine streamlining communication,
                boosting collaboration, and effortlessly sharing critical
                information—all from one powerful platform.
              </Typography>

              <Typography
                variant="h2"
                align="center"
                sx={{ marginTop: "20px", fontStyle: "italic" }}
              >
                Developed by a top real estate professional who understands your
                daily challenges, REACirc is designed to transform how your
                brokerage operates, enhancing every aspect of your team's
                performance.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModal(true)}
                style={{
                  backgroundColor: "#FFD700", // Yellow color
                  color: "#000", // Text color (black)
                  width: "100%", // Full width
                  maxWidth: "100%", // Ensures it does not exceed the viewport width
                  borderRadius: "8px", // Optional: rounded corners
                  margin: "20px 0", // Optional: adds space around the button
                }}
              >
                Schedule a Demo
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <section id="sectionOffset">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-10 bordered-list">
              <div className="block__list">
                <div className="text-center mb-4">
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "2rem", fontWeight: "bolder" }}
                    className="text-center mb-4"
                  >
                    Unlock the Full Potential of Your Brokerage with REACirc
                  </Typography>
                  <ul className="icon__check--blue mb-5">
                    <li>
                      <Typography variant="body1">
                        <strong>Unified Office Experience:</strong>
                        <br />
                        Imagine having your brokerage seamlessly connected. With
                        REACirc, whether you manage a single office or multiple
                        locations, you'll enhance collaboration and
                        communication across your team. Keep everyone in the
                        loop and ensure no critical updates are missed, no
                        matter the size of your operation.
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>
                          Effortless In-House Coming Soon Listing Promotion:
                        </strong>
                        <br />
                        Stay ahead of the competition with easy and efficient
                        coming soon listing promotion. REACirc allows your
                        agents to share their upcoming listings in-house,
                        ensuring that all agents in your office know about new
                        listings before the rest of the agents in the market
                        know about them.
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>
                          Centralized Access to Important Documents:
                        </strong>
                        <br />
                        No more lost files or scattered documents. REACirc
                        centralizes all your marketing materials, forms, and
                        other essential documents, making them easily accessible
                        and organized for your team.
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>Engaged and Informed Team:</strong>
                        <br />
                        Struggling to keep your agents engaged and connected?
                        REACirc offers a solution with custom forums for
                        important discussions and updates, allowing agents to
                        connect with each other within the office. Fostering a
                        collaborative environment and providing easy access to
                        valuable information that can enhance their business and
                        performance.
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>Encouraging Healthy Competition:</strong>
                        <br />
                        Inspire and motivate your agents by showcasing Top Agent
                        lists. REACirc fuels a culture of achievement by
                        highlighting and celebrating excellence. By leveraging
                        healthy competition, you can drive your team to
                        continually improve their performance. As noted by Tim
                        Ferriss, “Competition is a powerful tool. It can be an
                        excellent motivator if used correctly, pushing
                        individuals to exceed their limits and achieve their
                        best.”
                      </Typography>
                    </li>

                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>Streamlined Communication:</strong>
                        <br />
                        Say goodbye to juggling multiple tools like email,
                        messaging apps, file-sharing services, and
                        notifications. REACirc consolidates these functions into
                        one powerful platform. With REACirc, you can efficiently
                        manage agent communication, document sharing, and
                        important updates all in one place. Whether you're
                        handling one office or multiple locations, REACirc
                        enhances collaboration and ensures that your agents stay
                        connected and informed without the hassle of managing
                        disparate systems.
                      </Typography>
                    </li>

                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>Effective Training and Onboarding:</strong>
                        <br />
                        Transform your training and onboarding processes with
                        REACirc’s centralized hub for all resources. Instead of
                        managing various training materials across multiple
                        platforms, REACirc provides a single location where new
                        agents can quickly access essential resources, training
                        videos, and up-to-date information. This streamlined
                        approach not only accelerates the onboarding process but
                        also ensures that all agents, whether in a single office
                        or multiple locations, are consistently updated and
                        equipped with the knowledge they need to succeed.
                      </Typography>
                    </li>

                    <br />
                    <li>
                      <Typography variant="body1">
                        <strong>Future-Ready Features:</strong>
                        <br />
                        As REACirc evolves, you’ll benefit from new features
                        that continuously enhance your brokerage’s capabilities,
                        keeping you ahead of the curve.
                      </Typography>
                    </li>
                    <br />

                    <li>
                      <Typography variant="body1">
                        <strong>Affordable Pricing for All Brokerages</strong>
                        <br />
                        REACirc offers a flexible pricing model to accommodate
                        brokerages of all sizes. Our pricing is based on a fixed
                        monthly charge plus a per-agent fee, making it
                        affordable whether you manage a small office or multiple
                        locations. For a personalized quote tailored to your
                        brokerage’s needs, please reach out to us directly.
                      </Typography>
                    </li>
                  </ul>
                  <div className="block__list--optin">
                    <Typography
                      variant="h2"
                      sx={{ fontSize: "2rem", fontWeight: "bold" }}
                    >
                      Get Started with REACirc!
                    </Typography>
                    <Typography variant="body1">
                      Ready to see how REACirc can transform your brokerage?
                      Schedule a demo today to experience firsthand how our
                      platform can enhance your team's productivity and
                      collaboration. Simply sign up below to secure your spot
                      for an exclusive demo or consultation.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowModal(true)}
                      style={{
                        backgroundColor: "#FFD700", // Yellow color
                        color: "#000", // Text color (black)
                        width: "100%", // Full width
                        maxWidth: "100%", // Ensures it does not exceed the viewport width
                        borderRadius: "8px", // Optional: rounded corners
                        margin: "20px 0", // Optional: adds space around the button
                      }}
                    >
                      Schedule a Demo
                    </Button>
                    <Typography variant="body1">
                      REACirc will be available to a{" "}
                      <strong>Limited number of brokerages.</strong>
                      <br />
                      Sign up to be on the list and get early access.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: "#1976d2", // Blue background
            color: "#FFFFFF", // White text
            fontWeight: "bold", // Bold text
            textAlign: "center", // Center text
            padding: "16px", // Adjust padding if needed
          }}
        >
          Schedule a Demo
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginTop: "15px" }} paragraph>
            We're excited to show you how REACirc can transform your brokerage.
            Please provide the following details to schedule your personalized
            demo.
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              margin="normal"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              fullWidth
              margin="normal"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              required
            />
            <TextField
              label="Brokerage Name"
              name="brokerageName"
              fullWidth
              margin="normal"
              value={formData.brokerageName}
              onChange={handleChange}
              error={!!errors.brokerageName}
              helperText={errors.brokerageName}
              required
            />
            <TextField
              label="City"
              name="city"
              fullWidth
              margin="normal"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
              required
            />
            <TextField
              label="Province/State"
              name="province"
              fullWidth
              margin="normal"
              value={formData.province}
              onChange={handleChange}
              error={!!errors.province}
              helperText={errors.province}
              required
            />
            <TextField
              label="Email"
              type="email"
              name="email"
              fullWidth
              margin="normal"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              required
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              margin="normal"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              required
            />
            <TextField
              label="Number of Offices"
              name="numberOfOffices"
              fullWidth
              margin="normal"
              value={formData.numberOfOffices}
              onChange={handleChange}
              error={!!errors.numberOfOffices}
              helperText={errors.numberOfOffices}
              required
            />
            <TextField
              label="Total Number of Agents"
              name="totalNumberOfAgents"
              fullWidth
              margin="normal"
              value={formData.totalNumberOfAgents}
              onChange={handleChange}
              error={!!errors.totalNumberOfAgents}
              helperText={errors.totalNumberOfAgents}
              required
            />
          </form>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Submit Demo Request
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
      >
        <DialogTitle>Success!</DialogTitle>
        <DialogContent>
          <Typography>
            Your REACirc Brokerage demo request has been successfully submitted!
            <br />
            We will be in contact soon...{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSuccessDialog(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Brokerages;
