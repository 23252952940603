import { React, useState, useEffect } from "react";
import { Container, Button, Form, Modal } from "react-bootstrap";
import { checkWaitList, submitUserRequest } from "../services/userService";
import Creatable from "react-select/creatable"; // Import Creatable from react-select
import { provinces, citiesByProvince } from "../data/canadianCities"; // Import data
import Select from "react-select"; // Import react-select

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"; // Example icons
import { Typography, InputLabel, Grid, Box } from "@mui/material";

const Agents = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    province: "",
    brokerage: "",
    email: "",
    mobileNumber: "",
    photo: null,
    type: "REACirc-Agent",
    password: "", // Add password here
    passwordVerify: "",
  });
  const [formError, setFormError] = useState("");
  const [errors, setErrors] = useState({ mobileNumber: "", photo: "" }); // Add photo error here

  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [successModalShow, setSuccessModalShow] = useState(false); // State to manage success modal visibility
  const [photoPreview, setPhotoPreview] = useState(""); // State for image preview
  const [cityOptions, setCityOptions] = useState([]);
  const [number, setNumber] = useState(0);
  const [provinceOptions] = useState(provinces);
  const [waitListCount, setWaitListCount] = useState(0); // State for waitlist count

  // Update city options based on selected province
  useEffect(() => {
    if (formData.province) {
      const cities = citiesByProvince[formData.province] || [];
      // Remove duplicates

      const uniqueCities = Array.from(
        new Map(cities.map((city) => [city.value, city])).values()
      );

      const sortedCities = uniqueCities.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setCityOptions(sortedCities);
    } else {
      setCityOptions([]);
    }
  }, [formData.province]);

  useEffect(() => {
    if (formData.password && formData.passwordVerify) {
      if (formData.password === formData.passwordVerify) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Passwords Don't Match",
        }));
      }
    } else {
      // If either field is empty, don't show the error
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }
  }, [formData.password, formData.passwordVerify]);

  // Fetch waitlist count when the component mounts
  useEffect(() => {
    const fetchWaitListCount = async () => {
      try {
        const count = await checkWaitList();
        // console.log("count", count);
        setWaitListCount(count);
      } catch (error) {
        console.error("Failed to fetch waitlist count:", error);
      }
    };

    fetchWaitListCount();
  }, []);

  // Phone number formatting function
  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    // Validate field on change
    if (name === "mobileNumber") {
      const error = validatePhoneNumber(value);
      setErrors({ ...errors, [name]: error });
    }

    if (type === "file") {
      const file = files[0];
      if (file) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: file,
        }));

        // Create a file reader to generate a preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhotoPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: name === "mobileNumber" ? formatPhoneNumber(value) : value,
      }));
    }
  };

  const validatePhoneNumber = (value) => {
    // North American phone number pattern
    const phonePattern =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phonePattern.test(value) ? "" : "Invalid phone number format.";
  };

  // Handle select changes
  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData({
      ...formData,
      [actionMeta.name]: selectedOption ? selectedOption.value : "",
    });
  };

  // Handle city input changes
  const handleCityChange = (newValue) => {
    setFormData({
      ...formData,
      city: newValue ? newValue.value : "",
    });
  };
  // Function to format phone number
  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, ""); // Remove all non-numeric characters
  };

  const validatePasswords = () => {
    if (formData.password !== formData.passwordVerify) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Passwords Don't Match",
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
      return true;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validatePasswords()) {
      return;
    }

    if (!formData.photo) {
      setFormError("Please upload a headshot.");
      return;
    }

    try {
      const cleanedPhoneNumber = cleanPhoneNumber(formData.mobileNumber);
      const data = {
        ...formData,
        mobileNumber: cleanedPhoneNumber,
      };
      await submitUserRequest(data);

      // Call checkWaitList to get the current waitlist count
      const waitListCount = await checkWaitList();
      setNumber(waitListCount); // Update the number of accounts in the waitlist

      setFormError("");
      setModalShow(false); // Close the form modal on successful submit
      setSuccessModalShow(true); // Show the success modal
    } catch (error) {
      setErrors({ ...errors, form: error.message });
    }
  };

  const handleShow = () => setModalShow(true); // Show the form modal
  const handleClose = () => setModalShow(false); // Hide the form modal
  const handleSuccessClose = () => setSuccessModalShow(false); // Hide the success modal

  // Sort cityOptions alphabetically by the label
  const sortedCityOptions = [...cityOptions].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return (
    <>
      <section id="hero" className="section agents_bg__hero">
        <Container>
          <Grid className="row justify-content-center text-center">
            <Grid className="col-md-10">
              <Box className="option-icon-agents">
                <FontAwesomeIcon icon={faUser} />
              </Box>
              <Typography variant="h1">
                Elevate Your Real Estate Career with <br />
                <span className="highlight--yellow">REACirc!</span>
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1.5rem" }}>
                <strong>REACirc for Agents</strong>
              </Typography>

              <Typography variant="h2">
                Your gateway to an unparalleled network of real estate
                professionals. Connect across markets, collaborate seamlessly,
                and enhance your business practices and referral opportunities
                all within one powerful platform.
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "1.3rem" }}>
                <strong>Created by a Seasoned Real Estate Professional</strong>
              </Typography>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  marginTop: "0px",
                  fontStyle: "italic",
                  fontSize: "1.0rem",
                }}
              >
                With insider knowledge of the real estate industry, REACirc is
                crafted to revolutionize how you network and grow. This platform
                helps agents like you connect, collaborate, and excel, driving
                success in your business.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id="sectionOffset">
        <Container>
          <Grid className="row justify-content-center">
            <Grid className="col-lg-10 bordered-list">
              <Box className="block__list">
                <div className="text-center mb-4">
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "2rem", fontWeight: "bolder" }}
                    className="text-center mb-4"
                  >
                    Experience the Future of Real Estate Networking with REACirc
                  </Typography>
                  <ul className="icon__check--blue mb-5">
                    <li>
                      <strong>Expand Your Network Nationwide:</strong>
                      <br />
                      Imagine connecting with top agents from diverse markets,
                      sharing cutting-edge marketing strategies, collaborating
                      on successful business practices, and building a powerful
                      referral network. REACirc makes this a reality, helping
                      you grow your professional connections and business
                      opportunities like never before.
                    </li>
                    <br />
                    <li>
                      <strong>Join a Collaborative Community:</strong>
                      <br />
                      Step into a vibrant community of real estate professionals
                      who are as driven and passionate as you are. Engage in
                      meaningful collaborations, work together on innovative
                      projects, and gain valuable insights from peers across the
                      industry to enhance your career and success.
                    </li>
                    <br />
                    <li>
                      <strong>Unlock Exclusive Industry Insights:</strong>
                      <br />
                      Stay ahead of the curve by learning from the most
                      successful agents in different cities. With REACirc,
                      you'll gain access to the latest industry trends, best
                      practices, and proven strategies to keep your business
                      competitive and thriving.
                    </li>
                    <br />
                    <li>
                      <strong>Amplify Your Referral Potential:</strong>
                      <br />
                      Maximize your business growth by leveraging a network of
                      agents for high-quality referrals and strategic
                      partnerships. REACirc helps you tap into new client
                      opportunities and expand your reach, driving more success
                      and profitability for your business.
                    </li>
                    <br />
                    <li>
                      <strong>Be Ready for Exciting Future Features:</strong>
                      <br />
                      Get excited about upcoming enhancements like goal-setting
                      modules and video chat capabilities. REACirc is committed
                      to evolving and adding new tools that will support your
                      ongoing growth and success in the real estate industry.
                    </li>
                  </ul>
                  <Box
                    sx={{
                      padding: "2rem",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: 3,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginBottom: "0rem" }}
                    >
                      This Isn't for Everyone
                    </Typography>
                    <Typography sx={{ marginBottom: "1rem" }}>
                      Maybe Not Even You...
                    </Typography>

                    <Box
                      sx={{
                        marginBottom: "1.5rem",
                        padding: "1rem",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        boxShadow: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                      >
                        Transform Your Real Estate Business with Exclusive
                        Access to REACirc
                      </Typography>
                      <Typography>
                        For $100 per month, you gain access to a premium
                        platform designed for the elite few who are serious
                        about taking their real estate career to the next level.
                        This isn’t just a tool; it’s a game-changing investment
                        in your professional growth and success.
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ m: "1rem", fontWeight: "bolder" }}
                      >
                        Why REACirc is for the Serious Professional:
                      </Typography>
                      <ul className="icon__check--blue mb-3">
                        <li>
                          <strong>Unmatched Value:</strong>
                          {"  "}
                          With REACirc, you're not just paying for a
                          service—you're investing in a high-caliber network
                          that will accelerate your business growth.
                        </li>
                        <li>
                          <strong>Exclusive Community:</strong>
                          {"  "}
                          Join a select group of dedicated real estate
                          professionals who are committed to advancing their
                          careers and maximizing their potential.
                        </li>
                        <li>
                          <strong>Strategic Advantage:</strong> {"  "}Tap into
                          advanced networking and collaboration features that
                          give you a competitive edge in the real estate market.
                        </li>
                      </ul>
                      This opportunity isn’t for everyone. It’s for those who
                      are ready to invest in their future and leverage an elite
                      platform designed for serious professionals. <br />
                      <br />
                      If you're ready to make a significant impact in your
                      industry, REACirc is your key to unlocking unparalleled
                      success.
                    </Box>

                    {waitListCount <= 200 && (
                      <>
                        <Box
                          sx={{
                            padding: "1rem",
                            backgroundColor: "#fffae6",
                            borderRadius: "8px",
                            border: "2px solid #ffcc00",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "0.5rem",
                              color: "#ff6600",
                            }}
                          >
                            Exclusive Opportunity:
                          </Typography>
                          <Typography sx={{ marginBottom: "1rem" }}>
                            REACirc is offering this premium service to start
                            for just <strong>200</strong> real estate
                            professionals. This exclusive opportunity is
                            designed for professionals ready to invest seriously
                            in their growth and networking. <br />
                            <br />
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                marginBottom: "0.5rem",
                                color: "#ff6600",
                              }}
                            >
                              First Mover Advantage:
                            </Typography>
                            <Typography>
                              Be among the first 25 agents to join the waitlist
                              and enjoy a fantastic introductory offer:
                              <br />
                              <br />
                              Get your{" "}
                              <span style={{ color: "#ff6600" }}>
                                <strong>first 6 months at 100% off</strong>
                              </span>
                              , allowing you to start leveraging REACirc’s
                              powerful features for <b>FREE</b>.
                            </Typography>
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              marginBottom: "0.5rem",
                              color: "#ff6600",
                            }}
                          >
                            Second Mover Advantage:
                          </Typography>
                          Be among the next 25 agents to join the waitlist and
                          enjoy a fantastic introductory offer: get your{" "}
                          <span style={{ color: "#ff6600" }}>
                            <strong>first 6 months at 50% off</strong>
                          </span>
                          , allowing you to start leveraging REACirc’s powerful
                          features at an unbeatable value.
                          <br />
                          <br />
                          {waitListCount > 10 ? (
                            <Typography>
                              🌟 <strong>{waitListCount}</strong> agents are
                              already on the waitlist! Only{" "}
                              <strong>{200 - waitListCount}</strong> spots
                              remain.
                            </Typography>
                          ) : (
                            <Typography>
                              Be among the first to sign up and enjoy exclusive
                              benefits. Secure your spot today!
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleShow}
                      style={{
                        backgroundColor: "#FFD700", // Yellow color
                        color: "#000", // Text color (black)
                        width: "100%", // Full width
                        maxWidth: "100%", // Ensures it does not exceed the viewport width
                        borderRadius: "8px", // Optional: rounded corners
                        margin: "20px 0", // Optional: adds space around the button
                      }}
                    >
                      Join the Waitlist
                    </Button>
                  </Box>
                  <div className="block__list--optin">
                    <Typography
                      variant="h4"
                      sx={{ margin: "2rem auto", fontSize: "1.75rem" }}
                    >
                      <strong>Secure Your Spot Today</strong>
                    </Typography>
                    <p>
                      Don’t miss out on this exclusive opportunity. Sign up now
                      to secure your place among the first 200 agents and take
                      advantage of this limited-time offer. Transform your real
                      estate career with REACirc and start building valuable
                      connections and growing your business today!
                    </p>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static" // Prevents closing the modal by clicking outside of it
        keyboard={false} // Prevents closing the modal with the ESC key
      >
        <Modal.Header closeButton>
          <Modal.Title> Join REACirc Agents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-intro">
            We are excited to announce that REACirc Agent is scheduled to be
            live in October 2024. Be among the first to experience the next
            level of real estate networking and collaboration. Please fill out
            the form below to secure your spot and be updated with the latest
            information and offers.
          </p>
          <p className="modal-intro">
            Please fill out the form below to secure your spot and to be kept up
            to date on the launch date.
          </p>

          <Form onSubmit={handleFormSubmit} className="form-box">
            <Form.Group controlId="formFirstName">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formProvince">
              <div className="form-select">
                <Select
                  name="province"
                  options={provinceOptions}
                  value={provinceOptions.find(
                    (option) => option.value === formData.province
                  )}
                  onChange={handleSelectChange}
                  placeholder="Select Province"
                  isClearable
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formCity">
              <InputLabel style={{ marginLeft: "10px" }} htmlFor="formCity">
                City
              </InputLabel>
              <div className="form-select">
                <Creatable
                  name="city"
                  options={cityOptions}
                  value={
                    sortedCityOptions.find(
                      (option) => option.value === formData.city
                    ) || { label: formData.city, value: formData.city }
                  }
                  onChange={handleCityChange}
                  placeholder="Select or enter city"
                />
                <span style={{ marginLeft: "5px", fontSize: "10px" }}>
                  Type in your city if not found in the list
                </span>
              </div>
            </Form.Group>
            <Form.Group controlId="formBrokerage">
              <Form.Control
                type="text"
                placeholder="Brokerage Name"
                name="brokerage"
                value={formData.brokerage}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMobileNumber">
              <Form.Control
                type="tel"
                placeholder="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                isInvalid={!!errors.mobileNumber}
                required
              />
              <Form.Control.Feedback
                style={{ marginLeft: "10px" }}
                type="invalid"
              >
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Control
                type="password"
                placeholder="Create a Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formBasicPasswordVerify">
              <Form.Control
                type="password"
                name="passwordVerify"
                value={formData.passwordVerify}
                onChange={handleChange}
                placeholder="Verify your password"
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback
                style={{ marginLeft: "10px" }}
                type="invalid"
              >
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="file-upload" controlId="formPhoto">
              <Form.Control
                type="file"
                id="fileInput"
                name="photo"
                onChange={handleChange}
                accept="image/*"
                style={{ display: "none" }} // Hide the default file input
              />
              {formError && <p className="text-danger">{formError}</p>}
              {photoPreview && (
                <div className="photo-preview">
                  <img
                    src={photoPreview}
                    alt="Selected Preview"
                    style={{
                      maxWidth: "100%",
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  />
                </div>
              )}
              <div className="button-group">
                <label htmlFor="fileInput" className="file-label">
                  {formData.photo ? "Change Headshoot" : "Choose Headshot"}
                </label>
                {photoPreview && (
                  <label
                    variant="secondary"
                    onClick={() => {
                      setFormData({ ...formData, photo: null });
                      setPhotoPreview("");
                    }}
                    style={{
                      marginLeft: "10px",
                      color: "black",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    Clear Headshot
                  </label>
                )}
              </div>
            </Form.Group>

            <Button className="btn btn--optin" type="submit">
              Join the the Wait List
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Modal
        show={successModalShow}
        onHide={handleSuccessClose}
        size="lg"
        aria-labelledby="success-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="success-modal-title">Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="h6" className="text-center">
            Congratulations!
            <br />
          </Typography>
          <center>
            You are {number} on the wait list.
            <br />
            You have been added to the wait list.
            <br />
            We will keep you updated on the launch date.
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Agents;
